import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { useHAMobileApp } from '@ha/core/MobileApp';

import { Cookies, CookiesTime } from 'ha/constants/Cookies';
import { Experiments } from 'ha/constants/experiments';

import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { useExperiment } from 'ha/modules/Experiments/components/Experiment';
import { useCookie } from 'ha/pages/Search/hooks/useCookie';

export const useMobileAppBanner = () => {
  const { isHAMobileApp } = useHAMobileApp();
  const { mobileAppBanner } = useFeatureFlags();
  const [dismissedCookie, setDismissedCookie] = useCookie(
    Cookies.MOBILE_BANNER_DISMISSED,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const showMobileAppExperimentVariant = useExperiment(
    Experiments.TX3436.name,
    {
      disabledVariant: 'A',
      enabled: isEnabled(mobileAppBanner),
    },
  );

  const showMobileAppBanner = React.useMemo(() => {
    const isDismissed = Boolean(dismissedCookie === 'true');
    return (
      showMobileAppExperimentVariant &&
      !isDismissed &&
      isMobile &&
      !isHAMobileApp
    );
  }, [
    showMobileAppExperimentVariant,
    dismissedCookie,
    isMobile,
    isHAMobileApp,
  ]);

  const handleBannerClose = () => {
    // Set a simple 'true' value with an expiration date of 7 days
    setDismissedCookie('true', {
      expires: CookiesTime.SEVEN_DAYS,
    });
  };

  return {
    showMobileAppBanner,
    handleBannerClose,
  };
};
